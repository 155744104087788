<template>
  <div class="author">
    <el-form :model="queryInfo" class="ly-header__bg" label-width="100px">
      <el-form-item label="技术类别：">
        <dic-tag-button
          :code.sync="queryInfo.technologyName"
          query-name="technologyName"
          name="技术类别"
          tag="技术"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item,index) in tags"
          :key="index+item.name"
          @close="closeTag(index,item.tagName)"
        >
          {{item.name}}：{{queryInfo[item.tagName]?queryInfo[item.tagName]:'不限'}}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength')&&item.valLength>0"
          >+{{item.valLength}}</span>
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="ly-container__bg">
      <div class="inputVal">
        <el-input placeholder="请输入作者名称" v-model="queryInfo.authorNameCha" class="lager-input">
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        class="ly_table"
        :data="authorTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        :default-sort="{prop: 'date', order: 'descending'}"
        @sort-change="sortChange"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column prop="authorNameEng" label="作者名称(英文)" align="center" />
        <el-table-column prop="authorNameCha" label="作者名称(中文)" align="center" />
        <el-table-column prop="count" label="论文个数" align="center" sortable="custom" />
        <el-table-column prop="affiliation" label="所属机构(英文)" align="center" />
        <el-table-column prop="affiliationCha" label="所属机构(中文)" align="center" />
        <el-table-column prop="researchInterest" label="研究兴趣" align="center" />
        <el-table-column prop="email" label="邮箱" align="center" />
      </el-table>

      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import DicTagButton from '@/components/DicTagButton.vue'
import { pagination } from '@/mixins/pagination'
import { getAuthor } from '@/api/paper'
export default {
  name: 'Author',

  components: {
    DicTagButton,
  },

  mixins: [pagination],

  data() {
    return {
      queryInfo: {
        columnName: "update_time",
        pageSize: 10,
        pageNum: 1,
        order:1,
        technologyCategory: [], // 技术类别
        technologyName: '', // 技术辅助标签
        authorNameCha: '', // 作者名称
      },
      authorTable: [],
      tags: [
        {
          name: '技术类别',
          tagName: 'technologyName',
          valLength: 0,
        },
      ],
    }
  },

  created() {
    this.search()
  },

  methods: {
    async search() {
      const res = await getAuthor(this.queryInfo)

      if (res.code === 200) {
        this.authorTable = res.data.list

        this.total = res.data.total
      }
    },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "count") {
        this.queryInfo.columnName = "count";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      }
      this.search();
    },
    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      let childrenVal = []

      if (!this.tags.some((item) => item.tagName === queryName)) {
        if (name === '技术类别') {
          this.tags.push({
            name,
            tagName: queryName,
            valLength: 0,
          })
        } else {
          this.tags.push({
            name,
            tagName: queryName,
          })
        }
      }

      // 拿到name在tags的下标
      let result
      result = this.tags.findIndex((el) => {
        return el.name === name
      })

      switch (name) {
        case '技术类别':
          this.queryInfo.technologyCategory = [val]

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length

            childrenVal = checkboxVal

            this.queryInfo.technologyCategory =
              this.queryInfo.technologyCategory.concat(childrenVal)
          } else {
            this.tags[result].valLength = 0
          }

          break

        default:
          break
      }

      this.search()
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1)

      switch (name) {
        case 'technologyName':
          this.queryInfo.technologyName = ''
          this.queryInfo.technologyCategory = []
          break
        default:
          this.queryInfo[name] = ''
          break
      }
      this.search()
    },
  },
}
</script>

<style lang="less" scoped>
.author {
  .inputVal {
    display: flex;
    justify-content: flex-end;
    /deep/ .el-input-group__append {
      background: #1e9fff;
      color: #fff;
      border: 1px solid #1e9fff;
    }
    .caretTime:hover {
      cursor: pointer;
    }
  }
}
</style>
